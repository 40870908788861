<script setup lang="ts">
const props = defineProps<{
	refKey?: string | number
	bucket: 'user' | 'bg' | 'frame' | 'challenge'
	name: string
	alt?: string
	imgClass?: string
	size?: 'sm' | 'md' | 'lg' | 'xl' | Array<'sm' | 'md' | 'lg' | 'xl'>
}>();

const { $config } = useNuxtApp();

const bucketSettings = {
	user: {
		lg: '320x320',
		md: '200x200',
		sm: '80x80',
	},
	bg: {
		xxxl: '2048x1024',
		xxl: '1920x960',
		xl: '1460x840',
		lg: '1080x720',
		md: '720x480',
		sm: '538x538',
	},
	frame: {
		lg: '480x480',
		md: '300x300',
		sm: '120x120',
	},
	challenge: {
		lg: '420x280',
		sm: '300x200',
	},
};

const storageBucket = {
	default: $config.public.FIREBASE_DEFAULT_STORAGE_BUCKET,
	main: $config.public.FIREBASE_MAIN_STORAGE_BUCKET,
	user: $config.public.FIREBASE_USER_STORAGE_BUCKET,
	bg: $config.public.FIREBASE_BG_STORAGE_BUCKET,
	frame: $config.public.FIREBASE_FRAME_STORAGE_BUCKET,
	challenge: $config.public.FIREBASE_CHALLENGE_STORAGE_BUCKET,
};

const mediaMap = {
	sm: null,
	md: '(min-width:48em)',
	lg: '(min-width:62em)',
	xl: '(min-width:75em)',
	xxl: '(min-width:93.75em)',
	xxxl: '(min-width:106.25em)',
};

const getUrl = (size: string) => {
	let filename;
	if (size === 'default') {
		filename = props.name;
	}
	else {
		if (props.name) {
			const [name] = props.name.split('.');
			filename = encodeURIComponent(`thumbs\/${name}_${bucketSettings[props.bucket][size]}.webp`);
		}
	}

	const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${storageBucket[props.bucket]}/o`;

	const params = new URLSearchParams();
	params.append('alt', 'media');
	if (props.refKey)
		params.append('rnd', props.refKey.toString());

	return `${baseUrl}\/${filename}?${params}`;
};

const sizeOrder = ['xl', 'lg', 'md', 'sm'];
const sizesToRender = computed(() => {
	const availableSizes = Object.keys(bucketSettings[props.bucket]);

	let sizes;
	if (Array.isArray(props.size))
		sizes = props.size.filter(size => availableSizes.includes(size));

	else if (props.size)
		sizes = [props.size].filter(size => availableSizes.includes(size));

	else
		sizes = availableSizes;

	return sizes.sort((a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b));
});
</script>

<template>
	<picture>
		<template v-for="(itemSize, index) in sizesToRender" :key="size">
			<source :srcset="getUrl(itemSize)" :media="mediaMap[itemSize]" v-if="index !== sizesToRender.length - 1" />
			<source :srcset="getUrl(itemSize)" v-else />
		</template>
		<img :class="props.imgClass" :src="getUrl('default')" :alt="props.alt" />
	</picture>
</template>
